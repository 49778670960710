import * as _debug2 from "debug";

var _debug = "default" in _debug2 ? _debug2.default : _debug2;

var exports = {};
var debug;

exports = function () {
  if (!debug) {
    try {
      /* eslint global-require: off */
      debug = _debug("follow-redirects");
    } catch (error) {
      /* */
    }

    if (typeof debug !== "function") {
      debug = function () {
        /* */
      };
    }
  }

  debug.apply(null, arguments);
};

export default exports;